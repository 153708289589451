body {
  background-color: #e4e4e4;
  font-family: BrandonGrotesque, Sans;
  /* margin-bottom: 0px; */
  overflow-x: hidden;
  height: 100%;
}
@font-face {
  font-family: BrandonGrotesque;
  src: url("./fonts/BrandonGrotesque-Medium.otf");
}
@font-face {
  font-family: BrandonBold;
  src: url("./fonts/Brandon_bld.otf");
}
.logoHeader{
  display: none;
}
.fondo{
  background-color: #DFEEF8;
}
.containerS{
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}
#answers li {
  border-radius: 50px 0% 0% 50px;
}
.contenidoQuestion{
  background: inherit;
  position: absolute;
  overflow: hidden;
  
}
.contenidoQuestion span p span{
  font-style: italic;
}
.contenidoQuestion::before{
  /* background-color: rgba(255, 255, 255,0.5); */
  position: absolute;
  content: '';
  background: inherit;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  box-shadow: inset 0 0 0 3000px rgba(250, 250, 250, 0.6);
  filter: blur(10px);
  /* backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px); */
}

@media (max-width: 500px) {
  .header{
    width: 60%;
  }
  .contenido{
    display: block;
    width: 70% !important;
    text-align: left !important;
    padding: 0 9% !important;
    margin-top: 5% !important;
  }
  .contenido p{
    text-align: left;
    margin: auto;
  }
  .contenido a{
    width: 100%;
    margin: auto;
    padding: 5px 1px !important;
  }
  footer{
    width: 80% !important;
    margin: auto;
    padding-right: 0 !important;
    padding-top: 20px !important;
    text-align: center !important;
  }
  .contenidoQuestion{
    height: 120px;
    width:100%;
  }
  .ContenPregunta{
    margin-top:0px;
    width: 100%;
    height: 80px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .pregunta{
    margin: 0 10px;
    font-size: 4vw !important;
    line-height: 1 !important;
  }
  #answers p{
    font-size: 14px !important;
  }
}

@media (min-width: 501px) and (max-width: 768px) {
  .contenido{
    display: flex;
    justify-content: center;
  }
  .contenido p{
    width: 45%;
    text-align: left;
  }
  .contenido a{
    width: 39%;
    font-size: small;
    margin: auto;
  }
  .contenido span{
    font-size: x-large;
  }
  header{
    /* display: flex !important; */
    justify-content: space-between;
  }
  .logoHeader{
    display: inline-flex;
    width: 25%;
    height: 10%;
  }
  .header{
    width: 40%;
  }
  .containerS{
    padding: 0 15%;
    background-size: 101%;
    background-position: 85% 0%;
    background-color: #EFF2F9;
  }
  .contenidoQuestion{
    height: 200px;
    margin-left: auto !important;
    margin-right: auto !important;
    width: 100% !important;
  }
  .contenidoQuestion>span{
    height: 75.5%;
    width: 100%;
    margin: auto;
  }
  /* .contenidoQuestion{
    height: 120px;
    width:100%;
  } */
  .ContenPregunta{
    margin-top:0px;
    width: 100%;
    height:150px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .pregunta{
    margin: 0 20px;
    font-size: 4.5vw !important;
    line-height: 1 !important;
  }
  #answers li:hover{
    background-color: #DFEEF8;
  }
  #question p {
      padding-left: 0px;
      padding-right: 0px;
  }
  #answers li {
      width: 100%;
  }
  #submit button {
      width: 100%;
  }
}

@media (max-width:768px) {
  .footerPC{
    display: none !important;
  }
  #root{
    height: 100%;
  }
  .Image{
    width: inherit;
  }
  header{
    /* height: 15vh; */
    background-color: transparent;
    padding-right: 40px;
    padding: 15px;
    text-align: end;
    margin-bottom: 3%;
  }
  .fondo{
    height:70%;
    overflow: hidden;
  }
  .resultFondo{
    background-color: #BFC8D0;
    height:100%;
    overflow: hidden;
    padding: 0 !important;
    margin: 0 !important;
  }
  footer {
    /* height: 15vh; */
    width: 100%;
    background-color: transparent;
    color: white;
    margin-top: 0px;
    padding-top: 45px;
    padding-bottom: 10px;
    padding-right: 30px;
    text-align: end;
  }
  footer>a{
    margin: auto 0 auto 10px;
  }
  .footer{
    width: 30%;
    padding: 0 0 0 10px;
    border-left: solid 2px #004984;
  }
  #question {
    /* background-color: #c9d1d0; */
    width: 100%;
    color: #8B9696;
    padding: 0px;
    margin: auto;
    text-align: center;
    height: auto;
  }
  .containerS{
    padding: 0 10%;
    background-size: 101%;
    background-position: 85% 0%;
    background-color: #EFF2F9;
  }
  .contenidoQuestion {
    /* height: 100%; */
    display: inline;
    padding: 0 !important;
    margin: 0 0 6vh 0;
    border-radius: 30px;
  }
  .contenidoQuestion::before{
    /* bottom: 35% !important; */
    bottom: 0;
  }
  .contenido2 {
    /* height: 23vh;/*22vh*/ 
    display: inline;
    padding: 0 !important;
  }
  .contenido p {  
    /* font-family: Poppins-Regular, Sans; */
    font-size: calc(1rem + 1.5vw);/*2.3rem*/
  }
  .dots {
    width: 100%;
    max-width: 100%;
    margin: 0px;
    margin-top: 0px;
    padding: 0px 0px 0px 0px;
    position: absolute;
    bottom: 0;

  }
  .dots img{
    width: inherit;
    padding: 0 15px;
  }
  #question p{
    /* font-family: Poppins-Medium, Sans; */    
    font-weight: 700;
  }
  .questionBox {
    height: 65%;/*70vh*/
  }
  .mobileSlider {
    display: show;
  }
  .desktopSlider {
    display: none;
  }
  .contenido {
    width: 100%;
    /* height: 100%; */
    margin: auto;
    align-content: center;
    text-align: center;
    font-size: 3.2rem;
  }
  .resultImg {
    width: 70%;
    /* height: 100%; */
    margin: 0px;
    align-content: center;
  }
  .resultContenido {
    height: 100%;
    padding-top:0vh;
  }
  .imageHeader {
    margin-left: 0px;
  }
  .headerImg {
    width: 75%;
    /* height: 40%; */
    margin: auto;
    padding: 0px;
  }
  .resultTitle {
    text-align: center
  }
  .resultText {
    font-size: calc(1rem + 0.1vw);
    text-align: center;
  }
  .hostImg {
    height: inherit;
    margin: 0%;
    padding: 0% !important;
    text-align: center;
    overflow: hidden;
  }
  #answers {
    margin-top: 7%;
    background-color: transparent !important;
    min-height: 70px;
    width: 100%;
    display: flex;
    transition: color .3s ease, border-color .3s ease, transform .3s ease;
    cursor: pointer;
    /* height: 30vh; */
  }
  #answers ul{
    list-style-type: none;
    padding: 0px;
    margin-top: 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    /* height: 35vh; */
  }
  #answers li {
    /* font-family: Poppins-Medium, Sans; */
    background-color:#ffffff;
    min-height: 7px;
    max-height: 9vh;/*75px*/
    width: 98%;
    display: flex;
    align-items: center;
    margin: 5px 5px;
    transition: color .3s ease, border-color .3s ease, transform .3s ease;
    cursor: pointer;
  }
  #answers li div{
    width: 100%;
  }
  .iconList{
    max-width: 20%;/*80px*/
    margin: auto 0;
    padding: 4px 5px;
    max-height: 100%;
  }
  #answers li p {
    color: #0b0e21;
    align-self: center;
    font-size: 16px;
    font-weight: 600;
    margin: auto;
    transition: color .3s ease;
  }
  .box{ 
    /* display:none; */
    overflow: hidden;
  }
  .Banner {
    background-color: #D7DBDF;
    width: 100%;
    height:auto;
    margin: 0% 0% 0% 0%;
  }
  .Banner div:first-child:first-child{
    margin: 0 !important;
  }
  .Banner-text {
    color: grey;
    text-align: center;
    padding-top: 5px;
    /* font-family: Poppins-Medium, Sans; */
    font-size: 18px;
  }
  .Banner-text a{
    font-size: 20px !important;
  }
  .Banner-img{
    display: block;
    margin: auto;
    width: inherit;
  }
  .frame-text {
    color:white;
    /* font-family:Poppins-Regular; */
    font-size: 16px;
    margin: 4%;
    margin-left: -20%;
    margin-right: -5px;
    width: 150px; 
  }
  .trapezoid {
    width: 100%;
    background-color: #004986;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    text-align: center;
    margin:0px ;
    padding:0px; /*no mover de 0px se DAÑA :v */
  }
  .CardStyle {
    width: 95%;
    height: auto/*460px;/*65vh*/
  }
  .statesBTN{
    width: 350px;
    border: none;
    box-shadow: 5px 5px 10px rgba(55, 55, 55, 0.1);
    /* background-color: #DFEEF8 !important; */
    color: #004986;
    font-size: 1.5rem;
    padding: 5px 0px;
    font-weight: 600;
  }
  .statesBTN::after{
    border: none !important;
  }
  .statesBTN:hover{
    border: none;
    background-color: #DFEEF8 !important;
    color: #004986 !important;

  }
  .statesBTN:focus{
    border: none !important;
    background-color: #DFEEF8;
    color: #004986;

  }
  .statesBTN:active{
    background-color: #DFEEF8 !important;
  }
  .statesBTN:not(.statesBTN:focus){
    border: none;
    background-color: white;
    color: #004986;

  }
  .mainText{
    margin: 0 !important;
  }
  .downloadBTN{
    margin: 0 !important;
  }
  .downloadBTN a{
    font-size: 16px !important;
  }
  .redesPic{
    width: 32px;
    height: 32px;
    margin: 0 7px 0 0;
  }
  .redesPic2{
    width: 32px;
    height: 32px;
    margin: 0 7px 0 0;
  }
  .redesPic img{
    width: 24px;
  }
  .redesPic2 img{
    height: 24px;
  }
}

@media (min-width:769px) {
  .footerMobile{
    display: none !important;
  }
  #root{
    height: 100%;
  }
  .frame-text {
    color:white;
    /* font-family:Poppins-Regular; */
    font-size: 24px;
    margin:5%;
  }
  .trapezoid {
    width: 100%;
    background-color: #004986;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    text-align: center;
    margin:0px ;
    padding:0px; /*no mover de 0px se DAÑA :v */
  }
  .CardStyle {
    width: 95%;
    border: transparent !important;
    height: auto/*460px;/*65vh*/
  }
  .cardImg{
    /* max-height: 332px; */
    width: fit-content;
  }
  .downloadResult{
    display: flex;
    justify-content: center;
    margin-top: 3%;
  }
  .downloadResult button{
    font-size: 1.5rem;
  }
  .Banner {
    background-color: #D7DBDF;
    width: 100%;
    height:auto;
    margin: 0% 0% 0% 0%;
  }
  .Banner-text {
    width: 50%;
    margin: auto;
    color: #004986;
    text-align: left;
    padding-top: 5px;
    /* font-family: Poppins-Medium, Sans; */
    font-size: 40px;
  }
  .Banner-text a{
    font-size: 25px !important;
  }
  .box{ 
    overflow: hidden;
  }
  .Banner-img {
    height: 100%;
    overflow: hidden; 
  }
  .Banner-btn {
    background-color: #62788D;
    color: "#FFFFFF";
  }
  .questionBox{
    height: 70vh;
  }
  #question {
    /* background-color: #c9d1d0; */
    color: #8B9696;
    padding: 10px;
    text-align: center;
    margin-left: 10%;
  }

  #question p{
    /* font-family: Poppins-Medium, Sans; */
    font-size: 3.2rem;/*40px*/
    font-weight: 700;
    letter-spacing: 2px;
    line-height: 1;
  }

  #answers ul{
    list-style-type: none;
    padding: 0px;
    margin-bottom: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    height: 100%;
  }

  #answers li {
    /* font-family: Poppins-Medium, Sans; */
    background-color:#ffffff;
    min-height: 70px;
    max-height: 80px; /*90px*/
    width: 98%;
    display: flex;
    margin: 10px 10px;
    transition: color .3s ease, border-color .3s ease, transform .3s ease;
    cursor: pointer;
    align-items: center;
    /* box-shadow: 5px 5px 10px grey; */
  }
  #answers li div{
    width: 100%;
  }
  #answers li:hover{
    background-color: #DFEEF8;
  }

  #answers {
    background-color: transparent;
    min-height: 70px;
    max-height: 60vh;
    width: 100%;
    display: flex;
    transition: color .3s ease, border-color .3s ease, transform .3s ease;
    cursor: pointer;
    overflow: hidden;
    /* height: inherit; */
  }

  #answers li p {
    color: #0b0e21;
    font-size: 22px;
    font-weight: 600;
    flex: calc(100% - 75px);
    margin: auto 10px;
    transition: color .3s ease;
  }
  #answers li img{
    /* border-right: outset 1px #F6F6F6; */
    box-shadow: 5px 0px 5px 0px #F6F6F6;
  }

  #submit {
    text-align: center;
    margin: 20px 0;
  }

  header{
    height: 15vh;
    /* height: 15%; */
    background-color: transparent;
    padding-right: 40px;
    text-align: end;
  }

  footer {
    height: 15vh;
    /* height: 15%; */
    background-color: transparent;
    color: white;
    padding-top: 10px;
    padding-right: 40px;
    text-align: end;
    display: flex !important;
    justify-content: flex-end;
    align-items: center;
  }

  .footeranswer{
    background-color: #C7E5E9;
    color: #62788D;
    padding: 10px;
    text-align: center;
  }

  footer p {
    margin: 0;
  }
  .footer {
    margin: auto 0 auto 20px;
    padding: 0 0 0 10px;
    border-left: solid 2px #004984;
  }
  footer small {
    margin-bottom: 10px;
  }
  footer span {
    color: crimson;
  }
  .fondo{
    
    height:100%;
    max-height: 70vh;
    overflow: hidden;
  }
  .contenido {
    width: 100%;
    /* height: 100%; */
    margin: auto;
    align-content: center;
    font-size: 40px;
    line-height: 1.1;
  }
  .resultContenido {
    height: 100%;
    padding-top:5vh;
    padding-left:0px;
    padding-right:5vw;
  }
  .resultContenido p:first-of-type{
    width: 60% !important;
  }
  .headerContenido {
    width: 100%;
    height: 100%;
    margin: auto;
    padding: 0px;
    align-content: center;
  }
  .contenidoQuestion {
    width: 100%;
    margin-top: 2% ;
    border-radius: 40px;
  }
  .contenido2 {
    width: 100%;
    margin-top: 2% ;
  }
  .dots {
    width: 100%;
    height: auto;
    margin: 0px 0px 0px 0px;
    padding: 50px 0px 50px 0px;
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    border-radius: 0px 0px 20px 20px;
  }
  .ContenPregunta{
    margin-top:10px;
    width: 100%;
    height: 250px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .pregunta {
    width: 100%;
    margin: 0 15px!important;
    margin-left: 0%;
    font-size: 4vw !important;
    line-height: 1 !important;
  }
  .desktopSlider {
    display: show;
  }
  .mobileSlider {
    display: none;
  }
  .resultFondo{
    margin-bottom: 5%;
  }
  .headerImg{
    border: solid 1px;
    border-radius: 0 0 50em 50em;
    /* border-radius: 0 0 160px 160px; */
    background-color: #EFF2F9;
    position: relative;
  }
  .imageHeader{
    width: 85%;
    /* margin-top: 33%; */
    position: absolute;
    bottom: 8%;
    left: 8%;
  }
  .resultText {
    font-size: calc(1.1rem + 0.0vw);
    text-align: left;
  }
  .resultTitle {
    text-align: left;
  }
  .hostImg {
    text-align: end;
    overflow: hidden;
    padding: 0% !important;
  }
  .Image{
    display: block;
    margin: auto;
    width: inherit;
    max-height: 100%;
    
  }
  .iconList{
    height: 80%;
    margin: auto;
    padding: 0% 2%;
  }
  .statesBTN{
    width: 200px;
    border: none;
    box-shadow: 5px 5px 10px rgba(55, 55, 55, 0.1);
    /* background-color: #DFEEF8 !important; */
    color: #004788;
    font-size: 1.5rem;
    padding: 5px 0px;
    font-weight: 600;
  }
  .statesBTN::after{
    border: none !important;
  }
  .statesBTN:hover{
    border: none;
    background-color: #DFEEF8 !important;
    color: #004986 !important;

  }
  .statesBTN:focus{
    border: none !important;
    background-color: #DFEEF8;
    color: #004986;

  }
  .statesBTN:active{
    background-color: #DFEEF8 !important;
  }
  .statesBTN:not(.statesBTN:focus){
    border: none;
    background-color: white;
    color: #004986;

  }
  .downloadBTN a{
    font-size: 25px !important;
  }
  .redesPic{
    width: 32px;
    height: 32px;
    /* margin: auto; */
    margin: 0 7px 0 0px;
  }
  .redesPic2{
    width: 32px;
    height: 32px;
    /* margin: auto; */
    margin: 0 7px 0 0;
  }
  .redesPic img{
    width: 32px;
    margin: auto;
  }
  .redesPic2 img{
    height: 32px;
    margin: auto;
  }
}

@media (min-width:900px) {
  .pregunta {
    font-size: 3.5vw !important;
  }
}
@media (min-width:1400px) {
  .resultText {
    font-size: 1.5vw;
    margin-left:0%;
  }
  .pregunta {
    font-size: 3vw !important;
  }
}
